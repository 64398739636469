"use client";

import React from "react";
import {useForm} from "react-hook-form";
import {zodResolver} from "@hookform/resolvers/zod";

import {toast, wordings} from "@business/components/common/notification/Message";
import {tracker} from "@business/components/common/Tracker";
import {BasicContactFormSchema, basicContactFormSchema} from "./formsSchemas";
import {submitHubSpotForm} from "./helpers/submitHubSpotForm";
import Form from "../Form";
import TextField from "../text-field/TextField";

type BasicContactFormProps = {
    submitButtonText: string;
    formTracker: string;
};

const formReference = "basic-contact";

export default function BasicContactForm({submitButtonText, formTracker}: BasicContactFormProps) {
    const pageUri = typeof window !== "undefined" ? location.href : null;

    const {
        register,
        reset,
        handleSubmit,
        formState: {errors, isSubmitting},
    } = useForm<BasicContactFormSchema>({
        resolver: zodResolver(basicContactFormSchema),
    });

    const onSubmit = async (data: BasicContactFormSchema) => {
        try {
            const body = JSON.stringify({...data, pageUri});
            await submitHubSpotForm({body, formReference});
            tracker.submitForm({formReference});
            toast(wordings.form_submit_success);
            reset();
        } catch (error) {
            toast(wordings.issue_retry);
            tracker.error(error as Error);
        }
    };

    return (
        <Form submitButtonText={submitButtonText} isSubmitting={isSubmitting} onSubmit={handleSubmit(onSubmit)}>
            <TextField
                disabled={isSubmitting}
                error={errors.firstName?.message}
                label="Prénom"
                name="firstName"
                register={register}
            />
            <TextField
                disabled={isSubmitting}
                error={errors.lastName?.message}
                label="Nom"
                name="lastName"
                register={register}
            />
            <TextField
                disabled={isSubmitting}
                error={errors.email?.message}
                label="Email"
                name="email"
                register={register}
            />
            <input type="hidden" value={formReference} {...register("formReference")} />
            <input type="hidden" value={formTracker} {...register("formTracker")} />
        </Form>
    );
}
